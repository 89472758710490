<template>
	<div id="personal-info">
		<hr class="thicker-radius" />
		<legend>Personal Info</legend>
		<pre>{{ JSON.stringify(personalInfo, null, "\t") }}</pre>
		<!-- <label for=""></label>
			<input id="" type="text" :placeholder="" v-model="" /> -->
			<label for="name-first">{{ languageStrings.firstName }}</label>
		<input id="name-first" type="text" :placeholder="nameFirst" v-model="nameFirst" />
		<label for="name-last">{{ languageStrings.lastName }}</label>
		<input id="name-last" type="text" :placeholder="nameLast" v-model="nameLast" />
		<label for="birth-date">{{ languageStrings.birthDate }}</label>
		<input id="birth-date" type="date" :placeholder="birthDate" v-model="birthDate" />
		<label for="nationality">{{ languageStrings.nationality }}</label>
		<input id="nationality" type="text" :placeholder="nationality" v-model="nationality" />
		<label for="tax-id">{{ languageStrings.taxId }}</label>
		<input id="tax-id" type="text" :placeholder="taxId" v-model="taxId" />
		<label for="national-id">{{ languageStrings.nationalId }}</label>
		<input id="national-id" type="text" :placeholder="nationalId" v-model="nationalId" />
		<label for="address-number">{{ languageStrings.addressNumber }}</label>
		<input id="address-number" type="text" :placeholder="addressNumber" v-model="addressNumber" />
		<label for="address-suite">{{ languageStrings.addressSuite }}</label>
		<input id="address-suite" type="text" :placeholder="addressSuite" v-model="addressSuite" />
		<label for="address-neighborhood">{{ languageStrings.addressNeighborhood }}</label>
		<input id="address-neighborhood" type="text" :placeholder="addressNeighborhood" v-model="addressNeighborhood" />
		<label for="address-city">{{ languageStrings.addressCity }}</label>
		<input id="address-city" type="text" :placeholder="addressCity" v-model="addressCity" />
		<label for="address-county">{{ languageStrings.addressCounty }}</label>
		<input id="address-county" type="text" :placeholder="addressCounty" v-model="addressCounty" />
	</div>
</template>

<script>
export default {
	name: "PersonalInfoBasicTransactions",
	props: {
		playerState: Object,
		userProfile: Object,
		languageStrings: Object,
		languageErrorString: Object,
	},
	data() {
		return {
			personalInfo: this.playerState?.userProfile?.personalInfo,
			nameFirst: this.playerState?.userProfile?.personalInfo?.nameFirst,
			nameLast: this.playerState?.userProfile?.personalInfo?.nameLast,
			nameLast2: this.playerState?.userProfile?.personalInfo?.nameLast2,
			birthDate: new Date().toISOString().split("T")[0],
			nationality: this.playerState?.userProfile?.personalInfo?.nationality,
			emailAddress: this.playerState?.userProfile?.personalInfo?.emailAddress,
			emailVerificationStatus: this.playerState?.userProfile?.personalInfo?.emailVerificationStatus,
			taxId: this.playerState?.userProfile?.personalInfo?.taxId,
			nationalId: this.playerState?.userProfile?.personalInfo?.nationalId,
			addressNumber: this.playerState?.userProfile?.personalInfo?.addressNumber,
			addressStreet: this.playerState?.userProfile?.personalInfo?.addressStreet,
			addressSuite: this.playerState?.userProfile?.personalInfo?.addressSuite,
			addressNeighborhood: this.playerState?.userProfile?.personalInfo?.addressNeighborhood,
			addressCity: this.playerState?.userProfile?.personalInfo?.addressCity,
			addressCounty: this.playerState?.userProfile?.personalInfo?.addressCounty,
			addressState: this.playerState?.userProfile?.personalInfo?.addressState,
			addressCountry: this.playerState?.userProfile?.personalInfo?.addressCountry,
			addressZipCode: this.playerState?.userProfile?.personalInfo?.addressZipCode,
		};
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		async setPersonalInfo() {
			this.serverBusy = true;
			this.busyText = "Updating Personal Info";

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				userId: this.playerState.userId,
				userProfile: {
					personalInfo: {
						birthDate: this.birthDate,
						nationality: this.nationality,
						nameFirst: this.nameFirst,
						nameLast: this.nameLast,
						addressCountry: this.addressCountry,
						addressCounty: this.addressCounty,
						addressZipCode: this.addressZipCode,
					},
				},
				requiredValidations: "Minimal, BasicTransactions",
			};

			let requestUrl = new URL("/api/v1/user/profile", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request).catch(() => {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.somethingWentWrongTryLater;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				});

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (dataJson?.status === "FailedPersonalInfoValidation") {
					dataJson.validationErrors.forEach((err) => {
						this.status.ok = false;
						this.status.message = `${this.camelToTitleCase(err.field)}: ${this.camelToTitleCase(
							err.reason
						)}`;
						this.eventBus.emit("updateStatus", this.status);
					});
					this.serverBusy = false;
					this.busyText = "";
					return false;
				}

				if (dataJson?.status !== "Success") {
					this.status.ok = false;
					this.status.message = dataJson.status;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				}

				let newPlayerState = this.playerState;

				newPlayerState.userProfile = dataJson.userProfile;
				this.personalInfo = dataJson?.userProfile?.personalInfo;

				this.status.ok = true;
				this.status.message = "Profile Updated";
				this.eventBus.emit("updatePlayerState", newPlayerState);
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("updateUserProfile");
				this.populateFields();
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#personal-info {
	display: flex;
	flex-flow: column;
}
</style>
